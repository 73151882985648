import i18n from "i18next";
import {initReactI18next} from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: {
            "register.occupying.guest": "I confirm that I am the occupying guest.",
            "register.occupying.guest.error": "Please confirm you are the occupying guest.",
            "maintenance.new.upload.label": "Pictures/Videos",
            "maintenance.new.upload.encourage": "Please provide us with any photos or videos you have.",
            "maintenance.new.upload.attachment.error": "There was a problem with uploading some of your attachments.",
            "maintenance.new.issue.create.error.generic": "There was an error creating your maintenance issue. Please try again.",
            "extras.add.extras.to.your.booking": "Add Extras to your Booking now",
            "extras.third.party.link.label": "Check out our full extras list",
            "extras.third.party.button.link.label": "Show me the list >>",
            "booking.selector.label.current": "Current Bookings",
            "booking.selector.label.past": "Past Bookings",
            "booking.selector.label.future": "Future Bookings",
            "user.blocked.message": "Your user has been blocked from accessing the guest portal",
            "user.blocked.logged.out.generic": "Sorry there has been a problem, please try again",
            "button.go.back": "Go Back",
            "button.try.again": "Login",
            "button.logout": "Logout",
            "button.resend.link": "Resend Link",
            "message.resend.success": "Success! Please check your email.",
            "message.resend.fail": "Something has gone wrong, please try again or contact us.",
            "verify.captcha": "Please verify captcha",
            "label.directions": "Directions",
            "label.access.codes": "Access",
            "label.information": "Information",
            "label.contact.us": "Contact Us",
            "label.keys": "Keys",
            "label.building.description": "Building Description",
            "label.building.custom.description": "Building Information",
            "label.building.website.url": "Website",
            "label.building.property.worksheet": "Building Information",
            "label.building.address": "Building Address",
            "label.building.url.blurb": "Click here to visit our website",
            "label.building.worksheet.blurb": "Property Fact Sheet",
            "prompt.user.confirm.signup": "A verification link has been sent to your email address.\n To complete your registration please click the verify link in the email",
            "register.success.text": "Success! Please click below to login",
            "register.success.subtitle": "To complete your registration please click the verify link in the email we just sent you.",
            "no.active.bookings": "You have no active bookings, please contact us if you think this is in error."
        },
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
.init({
    resources,
    lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
        escapeValue: false, // react already safes from xss
    },
});

export default i18n;
