import {navigation} from "./reducers/topnav";

import {combineReducers} from "redux";
import {dashboard} from "./reducers/dashboard";
import {bills} from "./reducers/bills";
import {bookings} from "./reducers/bookings";
import {bill} from "./reducers/bill";
import {maintenances} from "./reducers/maintenances";
import {global} from "./reducers/global";
import {maintenance} from "./reducers/maintenance";
import {messages} from "./reducers/messages";
import {message} from "./reducers/message";
import {inventories} from "./reducers/inventories";
import {inventory} from "./reducers/inventory";
import {unitcontracts} from "./reducers/unitcontracts";
import {roomstays} from "./reducers/roomstays";
import {statements} from "./reducers/statements";
import {statement} from "./reducers/statement";
import {registration} from "./reducers/register";
import {extras} from "./reducers/extras";
import {marketing} from "./reducers/marketing";
import {ownersalesinvoices} from "./reducers/ownersalesinvoices";

const reducers = combineReducers({
  navigation,
  dashboard,
  bills,
  bill,
  bookings,
  maintenances,
  maintenance,
  messages,
  message,
  inventories,
  inventory,
  unitcontracts,
  roomstays,
  statements,
  statement,
  registration,
  marketing,
  global,
  extras,
  ownersalesinvoices,
});

export default reducers;
