import initialState from "../initialState";
import {
	MAINTENANCES_LOADING,
	CLEAR_MAINTENANCES,
	SET_MAINTENANCES,
	TOGGLE_MAINTENANCE_ITEM,
	MAINTENANCE_ITEM_NOTES_LOADING,
	SET_MAINTENANCE_ITEM_NOTES,
	SET_MAINTENANCES_TARGET_ITEMS,
	SET_FILTER_MAINTENANCES,
	TOGGLE_FILTER_MAINTENANCES, CLEAR_FILTER_MAINTENANCES
} from "../actionTypes";

export function maintenances(state = initialState.maintenances, action) {
	switch (action.type) {
		case SET_MAINTENANCES:
			console.log('SET_MAINTENANCES');
			return {...state, items: action.data};
		case SET_MAINTENANCES_TARGET_ITEMS:
			return {...state, targetItems: action.items};
		case CLEAR_MAINTENANCES:
			console.log('CLEAR_MAINTENANCES');
			return {...state, items: [], targetItems: [], filter: false};
		case MAINTENANCES_LOADING:
			console.log('MAINTENANCES_LOADING');
			return {...state, loading: action.toggle};
		case TOGGLE_MAINTENANCE_ITEM:
			console.log('TOGGLE_MAINTENANCE_ITEM');
			return {
				...state,
				items:
					{
						...state.items,
						[action.index]:
							{
								...state.items[action.index],
								expanded: !state.items[action.index].expanded
							}
					}
			};
		case SET_MAINTENANCE_ITEM_NOTES:
			return {...state, items: {...state.items, [action.id]: {...state.items[action.id], notes: action.notes}}};
		case MAINTENANCE_ITEM_NOTES_LOADING:
			console.log('MAINTENANCE_ITEM_NOTES_LOADING');
			return {...state, items: {...state.items, [action.id]: {...state.items[action.id], loading: action.toggle}}};
		case SET_FILTER_MAINTENANCES:
			return {...state, filter: action.filter};
		case CLEAR_FILTER_MAINTENANCES:
			return {...state, filter: false};
		case TOGGLE_FILTER_MAINTENANCES:
			return {...state, filterLoading: action.toggle};
		default:
			return state;
	}
}