import initialState from "../initialState";
import {
  EXTRAS_LOADING,
  EXTRAS_FAILED,
  SET_CURRENT_EXTRAS,
  SET_TARGET_EXTRAS,
  SET_SELECTED_EXTRA_TARGET_ITEM,
  SET_ADDING_EXTRAS_DETAILS,
  EXTRA_TARGET_ITEM_LOADING,
  EXTRA_TARGET_ITEM_FAIL, SET_EXTRAS_CONFIG,
} from "../actionTypes";

export function extras(state = initialState.extras, action) {
  switch (action.type) {
    case EXTRAS_LOADING:
      console.log("EXTRAS_LOADING");
      return { ...state, loading: action.toggle };
    case EXTRAS_FAILED:
      console.log("EXTRAS_FAILED");
      return { ...state, failed: action.toggle };
    case SET_CURRENT_EXTRAS:
      console.log("SET_CURRENT_EXTRAS");
      return { ...state, items: action.data };
    case SET_TARGET_EXTRAS:
      console.log("SET_TARGET_EXTRAS");
      return { ...state, targetItems: action.items };
    case SET_SELECTED_EXTRA_TARGET_ITEM:
      console.log("SET_SELECTED_EXTRA_TARGET_ITEM");
      return { ...state, selectedExtraTargetItem: action.items };
    case EXTRA_TARGET_ITEM_LOADING:
      console.log("EXTRA_TARGET_ITEM_LOADING");
      return { ...state, selectedExtraTargetItem: action.toggle };
    case EXTRA_TARGET_ITEM_FAIL:
      console.log("EXTRA_TARGET_ITEM_FAIL");
      return { ...state, selectedExtraTargetItem: action.toggle };
    case SET_ADDING_EXTRAS_DETAILS:
      console.log("SET_ADDING_EXTRAS_DETAILS");
      if (!action.value) {
        return {
          ...state,
          addingExtrasDetails: undefined,
        };
      }
      return {
        ...state,
        addingExtrasDetails: {
          status: action.value.status,
          message: action.value.message,
        },
      };
    case SET_EXTRAS_CONFIG:
        console.log("SET_EXTRAS_CONFIG");
        return {
          ...state,
          config: action.payload
        }
    default:
      return state;
  }
}
