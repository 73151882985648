import {
	CLEAR_DASHBOARD,
	DASHBOARD_LOADING,
	SET_DASHBOARD,
	SET_DASHBOARD_ARRIVAL_INFORMATION,
	SET_SELECTED_DASHBOARD_BUILDING
} from "../actionTypes";
import initialState from "../initialState";

export function dashboard(state = initialState.dashboard, action) {

	switch (action.type) {
		case SET_DASHBOARD:
			console.log('SET_DASHBOARD');
			return Object.assign({}, state, {
				dashboard: action.data
			});
		case CLEAR_DASHBOARD:
			console.log('CLEAR_DASHBOARD');
			return {...state, dashboard: [], dashboardArrivals: [], selectedArrival: false};
		case DASHBOARD_LOADING:
			console.log('DASHBOARD_LOADING');
			return Object.assign({}, state, {
				loading: action.toggle
			});
		case SET_DASHBOARD_ARRIVAL_INFORMATION:
			console.log('SET_DASHBOARD_ARRIVAL_INFORMATION');
			return {...state, dashboardArrivals: action.data};
		case SET_SELECTED_DASHBOARD_BUILDING:
			console.log('SET_SELECTED_DASHBOARD_BUILDING');
			return {...state, selectedArrival: action.data};
		default:
			return state;
	}
}

