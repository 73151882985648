import initialState from "../initialState";
import {
	CLEAR_INVENTORY, CLEAR_INVENTORY_PDF,
	INVENTORY_FAILED,
	INVENTORY_LOADING, INVENTORY_PDF_FAILED,
	INVENTORY_PDF_LOADING,
	SET_INVENTORY,
	SET_INVENTORY_PDF
} from "../actionTypes";


export function inventory(state = initialState.inventory, action) {
	switch (action.type) {
		case SET_INVENTORY:
			console.log('SET_INVENTORY');
			return {...state, item: action.item};
		case CLEAR_INVENTORY:
			console.log('CLEAR_INVENTORY');
			return {...state, item: false};
		case INVENTORY_LOADING:
			console.log('INVENTORY_LOADING');
			return {...state, loading: action.toggle};
		case INVENTORY_FAILED:
			console.log('INVENTORY_FAILED');
			return {...state, failed: action.toggle};
		case SET_INVENTORY_PDF:
			return {...state, pdf: action.pdf};
		case INVENTORY_PDF_LOADING:
			return {...state, pdfLoading: action.toggle};
		case INVENTORY_PDF_FAILED:
			return {...state, pdfFailed: action.toggle};
		case CLEAR_INVENTORY_PDF:
			return {...state, pdf: false, pdfLoading: false, pdfFailed: false};
		default:
			return state;
	}
}