import initialState from "../initialState";
import {CLEAR_MESSAGE, MESSAGE_FAILED, MESSAGE_LOADING, SET_MESSAGE} from "../actionTypes";

export function message(state = initialState.message, action) {
	switch (action.type) {
		case SET_MESSAGE:
			console.log('SET_MESSAGE');
			return {...state, item: action.item};
		case CLEAR_MESSAGE:
			console.log('CLEAR_MESSAGE');
			return {...state, item: false};
		case MESSAGE_LOADING:
			console.log('MESSAGE_LOADING');
			return {...state, loading: action.toggle};
		case MESSAGE_FAILED:
			console.log('MESSAGE_FAILED');
			return {...state, failed: action.toggle};
		default:
			return state;
	}
}