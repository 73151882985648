import {Auth} from './amplify';
import {getInterceptor, setInterceptor} from './api-consts';

import instance from './api-instance';

if (getInterceptor()) {
	instance.interceptors.request.eject(getInterceptor());
}

setInterceptor(instance.interceptors.request.use(config => {
	return Auth.currentSession().then(
		token => {
			config.headers.Authorization = `Bearer ${token.accessToken.jwtToken}`;
			config.headers['Content-Type'] = 'application/json';
			return Promise.resolve(config);
		},
		error => {
			return Promise.reject(error);
		},
	);

}));

export default instance;
