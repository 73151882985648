import initialState from "../initialState";
import {BILL_DOWNLOAD_TOGGLE, BILL_LOADING, CLEAR_BILL, SET_BILL} from "../actionTypes";

export function bill(state = initialState.bill, action) {
	switch (action.type) {
		case SET_BILL:
			console.log('SET_BILL');
			return {...state, item: action.bill};
		case CLEAR_BILL:
			console.log('CLEAR_BILL');
			return {...state, item: false};
		case BILL_LOADING:
			console.log('BILL_LOADING');
			return {...state, loading: action.toggle};
		case BILL_DOWNLOAD_TOGGLE:
			console.log('BILL_DOWNLOAD_TOGGLE');
			return {...state, downloadLoading: !state.downloadLoading};
		default:
			return state;
	}
}