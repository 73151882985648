import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux'
import store from "./store/store";
import {BrowserRouter} from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import getEnv from './service/getEnv';
import './i18n/i18n';

/*import whyDidYouUpdate from 'why-did-you-update';
whyDidYouUpdate(React);*/

Sentry.init({
	dsn: "https://5dcfb9e7ae5447d391fb48dcaf8dbe1c@o472191.ingest.sentry.io/5644350",
	integrations: [new Integrations.BrowserTracing()],

	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,
	environment: getEnv(),
	release: `guest-portal@${process.env.REACT_APP_GIT_ENV}`
});

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<App/>
		</BrowserRouter>
	</Provider>

	, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
