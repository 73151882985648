import initialState from "../initialState";
import {
	CLEAR_MAINTENANCE,
	CLEAR_MAINTENANCE_ITEM_NOTES,
	CLEAR_MAINTENANCE_TYPES,
	CREATE_NEW_MAINTENANCE_LOADING,
	MAINTENANCE_ITEM_ATTACHMENTS_LOADING,
	MAINTENANCE_ITEM_NOTES_LOADING,
	MAINTENANCE_LOADING,
	MAINTENANCE_LOADING_ADD_MESSAGE,
	MAINTENANCE_TYPES_LOADING,
	SET_MAINTENANCE, SET_MAINTENANCE_ITEM_ATTACHMENTS,
	SET_MAINTENANCE_ITEM_NOTES,
	SET_MAINTENANCE_TYPES,
} from "../actionTypes";

export function maintenance(state = initialState.maintenance, action) {
	switch (action.type) {
		case SET_MAINTENANCE:
			console.log('SET_MAINTENANCE');
			return {...state, item: action.maintenance};
		case CLEAR_MAINTENANCE:
			console.log('CLEAR_MAINTENANCE');
			return {...state, item: false, loading: false, failed: false, notes: [], notesLoading: false, addMessageLoading: false};
		case MAINTENANCE_LOADING:
			console.log('MAINTENANCE_LOADING');
			return {...state, loading: action.toggle};
		case MAINTENANCE_ITEM_NOTES_LOADING:
			return {...state, notesLoading: action.toggle};
		case MAINTENANCE_ITEM_ATTACHMENTS_LOADING:
			return {...state, attachmentsLoading: action.toggle};
		case SET_MAINTENANCE_ITEM_NOTES:
			return {...state, notes: action.notes};
		case SET_MAINTENANCE_ITEM_ATTACHMENTS:
			return {...state, attachments: action.attachments};
		case CLEAR_MAINTENANCE_ITEM_NOTES:
			return {...state, notes: []};
		case MAINTENANCE_LOADING_ADD_MESSAGE:
			return {...state, addMessageLoading: action.toggle};
		case SET_MAINTENANCE_TYPES:
			return {...state, types: action.types};
		case CLEAR_MAINTENANCE_TYPES:
			console.log('CLEAR_MAINTENANCE_TYPES');
			return {...state, types: []};
		case MAINTENANCE_TYPES_LOADING:
			return {...state, typesLoading: action.toggle};
		case CREATE_NEW_MAINTENANCE_LOADING:
			return {...state, createLoading: action.toggle};
		default:
			return state;
	}
}
