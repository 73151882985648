import {TOGGLE_TOP_NAV, TOGGLE_TOP_NAV_BREAKPOINT} from "../actionTypes";
import initialState from "../initialState";

export function navigation(state = initialState.topnav, action) {

	switch (action.type) {
		case TOGGLE_TOP_NAV:
			console.log('TOGGLE_TOP_NAV');
			return {...state, sideMenuCollapsed: action.flag};
		case TOGGLE_TOP_NAV_BREAKPOINT:
			return {...state, sideMenuBreakpoint: action.flag};
		default:
			return state;
	}
}

