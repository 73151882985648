import initialState from "../initialState";
import {
    CLEAR_ROOM_STAYS,
    ROOM_STAYS_LOADING, SET_CALENDAR_DTOS,
    SET_CURRENT_ROOM_STAYS, SET_FUTURE_ROOM_STAYS,
    SET_PAST_ROOM_STAYS,
    SET_ROOM_STAYS
} from "../actionTypes";

export function roomstays(state = initialState.roomstays, action) {
    switch (action.type) {
        case SET_ROOM_STAYS:
            console.log('SET_ROOM_STAYS');
            return {...state, items: action.data};
        case SET_CURRENT_ROOM_STAYS:
            console.log('SET_CURRENT_ROOM_STAYS');
            return {...state, currentRoomStays: action.data};
        case SET_PAST_ROOM_STAYS:
            console.log('SET_PAST_ROOM_STAYS');
            return {...state, pastRoomStays: action.data};
        case SET_FUTURE_ROOM_STAYS:
            console.log('SET_FUTURE_ROOM_STAYS');
            return {...state, futureRoomStays: action.data};
        case CLEAR_ROOM_STAYS:
            console.log('CLEAR_ROOM_STAYS');
            return {...state, items: []};
        case ROOM_STAYS_LOADING:
            console.log('ROOM_STAYS_LOADING');
            return {...state, loading: action.toggle};
        case SET_CALENDAR_DTOS:
            return {...state, calendarDtos: action.data};
        default:
            return state;
    }
}