import React, {Fragment, Suspense} from 'react';
import './App.css';
import '@aws-amplify/ui/dist/style.css';
import './api/amplify';
import {Auth} from './api/amplify';
//import SkeletonWrapper from "./components/Skeleton";
//import GuestPortal from "./components/GuestPortal";

import {withRouter} from "react-router";
const GuestPortal = React.lazy(() => import("./components/GuestPortal"));
const Register = React.lazy(() => import("./components/Register"));


class App extends React.Component {

	state = {
		register: undefined
	};

	componentDidMount() {
		Auth.currentAuthenticatedUser()
		.then(() => {
			// we have a user let just render
			this.setState({
				register: false
			});
		})
		.catch((...errors) => {
			const { location } = this.props;
			if (errors && errors[0] === "not authenticated") {
				// lets check the url
				if (location && location.pathname.startsWith("/register")) {
					// we are on the register screen and not logged in, lets set the register flag
					this.setState({
						register: true
					});
				} else {
					this.setState({
						register: false
					});
				}
			} else {
				this.setState({
					register: false
				});
			}
		});
	}

	render() {
		const { register } = this.state;

		if (register === undefined) {
			return null;
		}

		if (register) {
			// lets render the register stuff
			const { location } = this.props;
			const { pathname } = location;

			const shard = pathname.split("/").pop();

			return <Suspense fallback={<Fragment />}>
				<Register shard={shard} />
			</Suspense>;
		}

		return <Suspense fallback={<Fragment />}>
            <GuestPortal/>
        </Suspense>;
	}
}

export default withRouter(App);
