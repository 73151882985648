import initialState from "../initialState";
import {CLEAR_STATEMENTS, STATEMENTS_LOADING, SET_STATEMENTS} from "../actionTypes";

export function statements(state = initialState.statements, action) {
    switch (action.type) {
        case SET_STATEMENTS:
            console.log('SET_STATEMENTS');
            return {...state, items: action.data};
        case CLEAR_STATEMENTS:
            console.log('CLEAR_STATEMENTS');
            return {...state, items: []};
        case STATEMENTS_LOADING:
            console.log('STATEMENTS_LOADING');
            return {...state, loading: action.toggle};
        default:
            return state;
    }
}
