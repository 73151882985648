import initialState from "../initialState";
import {SET_MARKETING_LISTS} from "../actionTypes";

export function marketing(state = initialState.marketing, action) {
	switch (action.type) {
		case SET_MARKETING_LISTS:
			return {...state, marketingLists: action.marketingLists}
		default:
			return state;
	}
}
