import initialState from "../initialState";
import {BILLS_LOADING, CLEAR_BILLS, SET_BILLS} from "../actionTypes";

export function bills(state = initialState.bills, action) {
	switch (action.type) {
		case SET_BILLS:
			console.log('SET_BILLS');
			return {...state, items: action.data};
		case CLEAR_BILLS:
			console.log('CLEAR_BILLS');
			return {...state, items: []};
		case BILLS_LOADING:
			console.log('BILLS_LOADING');
			return {...state, loading: action.toggle};
		default:
			return state;
	}
}