import initialState from "../initialState";
import {
	SET_OWNER_FAILED_INVOICES, SET_OWNER_INVOICE_DOWNLOADING_TOGGLE,
	SET_OWNER_INVOICES_TOTAL,
	SET_OWNER_LOADING_INVOICES,
	SET_OWNER_SALES_INVOICES
} from "../actionTypes";

export function ownersalesinvoices(state = initialState.ownerSalesInvoices, action) {
	switch(action.type) {
		case SET_OWNER_LOADING_INVOICES:
			console.log(SET_OWNER_LOADING_INVOICES);
			return {
				...state,
				loading: action.payload,
			};
		case SET_OWNER_FAILED_INVOICES:
			return {
				...state,
				failed: action.payload,
			};
		case SET_OWNER_INVOICE_DOWNLOADING_TOGGLE:
			console.log("SET_OWNER_INVOICE_DOWNLOADING_TOGGLE");
			return {
				...state,
				downloadingUUID: action.uuid
			}
		case SET_OWNER_SALES_INVOICES:
			const invs = action.payload.map(i => {
				return {
					...i,
					totals: {
						gross: Number(i.totals.gross).toFixed(2),
						net: Number(i.totals.net).toFixed(2),
						vat: Number(i.totals.vat).toFixed(2),
					}
				};
			});
			return {
				...state,
				invoices: invs,
			};
		case SET_OWNER_INVOICES_TOTAL:
			const totals = action.payload;
			totals.gross = Number(totals.gross).toFixed(2);
			totals.net = Number(totals.net).toFixed(2);
			totals.vat = Number(totals.vat).toFixed(2);
			return {
				...state,
				totals: totals,
			};
		default:
			return state;
	}
}