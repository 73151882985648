import initialState from "../initialState";
import {
	CLEAR_INVENTORIES,
	INVENTORIES_LOADING,
	SET_INVENTORIES,
	SET_INVENTORIES_FAILED,
	SET_INVENTORIES_TARGET_ITEMS
} from "../actionTypes";

export function inventories(state = initialState.inventories, action) {
	switch (action.type) {
		case SET_INVENTORIES:
			console.log('SET_INVENTORIES');
			return {...state, items: action.data};
		case SET_INVENTORIES_TARGET_ITEMS:
			console.log('SET_INVENTORIES_TARGET_ITEMS');
			return {...state, targetItems: action.items};
		case INVENTORIES_LOADING:
			console.log('INVENTORIES_LOADING');
			return {...state, loading: action.toggle};
		case CLEAR_INVENTORIES:
			console.log('CLEAR_INVENTORIES');
			return {...state, items: {}, targetItems: []};
		case SET_INVENTORIES_FAILED:
			console.log('SET_INVENTORIES_FAILED');
			return {...state, failed: action.toggle};
		default:
			return state;
	}
}