import {
	GLOBAL_CLEAR_LAST_BREADCRUMB, GLOBAL_SET_CONTACTABLE_INFORMATION,
	GLOBAL_SET_LAST_BREADCRUMB,
	GLOBAL_SET_TITLE,
	GLOBAL_SET_UPGRADE_REQUIRED, SET_GUEST_CONFIG, SET_OWNER_CONFIG,
	SET_PORTAL_TYPE, SET_STRIPE_ENABLED,
	// SET_SAMPLE_GLOBAL_STATE
} from "../actionTypes";

import initialState from "../initialState";

export function global(state = initialState.global, action) {
	switch (action.type) {
		case GLOBAL_SET_TITLE:
			console.log('GLOBAL_SET_TITLE');
			return {...state, title: action.title};
		case GLOBAL_SET_LAST_BREADCRUMB:
			console.log('GLOBAL_SET_LAST_BREADCRUMB');
			return {...state, lastBreadcrumb: action.breadcrumb};
		case GLOBAL_CLEAR_LAST_BREADCRUMB:
			console.log('GLOBAL_CLEAR_LAST_BREADCRUMB');
			return {...state, lastBreadcrumb: ""};
        case GLOBAL_SET_UPGRADE_REQUIRED:
            console.log('GLOBAL_SET_UPGRADE_REQUIRED');
            return {...state, upgradeRequired: true};
		case SET_PORTAL_TYPE:
			console.log('SET_PORTAL_TYPE');
			return {...state, portalType: action.payload.type};
		case SET_STRIPE_ENABLED:
			console.log('SET_STRIPE_ENABLED');
			return {...state, stripeEnabled: action.payload};
		case SET_OWNER_CONFIG:
			console.log('SET_OWNER_CONFIG');
			return {
				...state,
				ownerConfig: action.payload.ownerConfig
			}
		case SET_GUEST_CONFIG:
			console.log('SET_GUEST_CONFIG');
			return {
				...state,
				guestConfig: action.payload.guestConfig
			}
		case GLOBAL_SET_CONTACTABLE_INFORMATION:
			return {
				...state,
				contactableInformation: action.payload
			}
		default:
			return state;
	}
}
