import axios from 'axios';
import configFactory from '../config/config';
import getEnv from '../service/getEnv';

const config = configFactory(getEnv());

const instance = axios.create({
    baseURL: config.baseUrl,
    timeout: config.timeout,
    headers: {
        'Content-Type': 'application/json',
        'X-App-Ver': process.env.REACT_APP_GIT_ENV,
    },
});

export default instance;
