export const TOGGLE_TOP_NAV = "TOGGLE_TOP_NAV";
export const TOGGLE_TOP_NAV_BREAKPOINT = "TOGGLE_TOP_NAV_BREAKPOINT";

export const GET_DASHBOARD = "GET_DASHBOARD";
export const SET_DASHBOARD = "SET_DASHBOARD";
export const CLEAR_DASHBOARD = "CLEAR_DASHBOARD";
export const DASHBOARD_LOADING = "DASHBOARD_LOADING";
export const SET_SELECTED_DASHBOARD_BUILDING =
  "SET_SELECTED_DASHBOARD_BUILDING";
export const SET_DASHBOARD_ARRIVAL_INFORMATION =
  "SET_DASHBOARD_ARRIVAL_INFORMATION";

export const SET_BILLS = "SET_BILLS";
export const CLEAR_BILLS = "CLEAR_BILLS";
export const BILLS_LOADING = "BILLS_LOADING";

export const SET_BILL = "SET_BILL";
export const CLEAR_BILL = "CLEAR_BILL";
export const BILL_LOADING = "BILL_LOADING";
export const BILL_DOWNLOAD_TOGGLE = "BILL_DOWNLOAD_TOGGLE";

export const SET_MAINTENANCES = "SET_MAINTENANCES";
export const SET_MAINTENANCES_TARGET_ITEMS = "SET_MAINTENANCES_TARGET_ITEMS";
export const CLEAR_MAINTENANCES = "CLEAR_MAINTENANCES";
export const MAINTENANCES_LOADING = "MAINTENANCES_LOADING";

export const TOGGLE_MAINTENANCE_ITEM = "TOGGLE_MAINTENANCE_ITEM";

export const MAINTENANCE_ITEM_ATTACHMENTS_LOADING = "MAINTENANCE_ITEM_ATTACHMENTS_LOADING";
export const SET_MAINTENANCE_ITEM_ATTACHMENTS = "SET_MAINTENANCE_ITEM_ATTACHMENTS";

export const SET_MAINTENANCE_ITEM_NOTES = "SET_MAINTENANCE_ITEM_NOTES";
export const CLEAR_MAINTENANCE_ITEM_NOTES = "CLEAR_MAINTENANCE_ITEM_NOTES";
export const MAINTENANCE_ITEM_NOTES_LOADING = "MAINTENANCE_ITEM_NOTES_LOADING";

export const SET_MAINTENANCE = "SET_MAINTENANCE";
export const CLEAR_MAINTENANCE = "CLEAR_MAINTENANCE";
export const MAINTENANCE_LOADING = "MAINTENANCE_LOADING";
export const MAINTENANCE_LOADING_ADD_MESSAGE =
  "MAINTENANCE_LOADING_ADD_MESSAGE";
export const MAINTENANCE_ADD_MESSAGE = "MAINTENANCE_ADD_MESSAGE";
export const CREATE_NEW_MAINTENANCE_LOADING = "CREATE_NEW_MAINTENANCE_LOADING";

export const SET_MAINTENANCE_TYPES = "SET_MAINTENANCE_TYPES";
export const CLEAR_MAINTENANCE_TYPES = "CLEAR_MAINTENANCE_TYPES";
export const MAINTENANCE_TYPES_LOADING = "MAINTENANCE_TYPES_LOADING";

export const SET_FILTER_MAINTENANCES = "SET_FILTER_MAINTENANCES";
export const CLEAR_FILTER_MAINTENANCES = "CLEAR_FILTER_MAINTENANCES";

export const TOGGLE_FILTER_MAINTENANCES = "TOGGLE_FILTER_MAINTENANCES";

export const SET_MESSAGES = "SET_MESSAGES";
export const SET_MESSAGES_TARGET_ITEMS = "SET_MESSAGES_TARGET_ITEMS";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
export const MESSAGES_LOADING = "MESSAGES_LOADING";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const MESSAGE_LOADING = "MESSAGE_LOADING";
export const MESSAGE_FAILED = "MESSAGE_FAILED";

export const SET_INVENTORIES = "SET_INVENTORIES";
export const SET_INVENTORIES_TARGET_ITEMS = "SET_INVENTORIES_TARGET_ITEMS";
export const CLEAR_INVENTORIES = "CLEAR_INVENTORIES";
export const INVENTORIES_LOADING = "INVENTORIES_LOADING";
export const SET_INVENTORIES_FAILED = "SET_INVENTORIES_FAILED";

export const SET_INVENTORY = "SET_INVENTORY";
export const CLEAR_INVENTORY = "CLEAR_INVENTORY";
export const INVENTORY_LOADING = "INVENTORY_LOADING";
export const INVENTORY_FAILED = "INVENTORY_FAILED";

export const SET_INVENTORY_PDF = "SET_INVENTORY_PDF";
export const INVENTORY_PDF_LOADING = "INVENTORY_PDF_LOADING";
export const INVENTORY_PDF_FAILED = "INVENTORY_PDF_FAILED";
export const CLEAR_INVENTORY_PDF = "CLEAR_INVENTORY_PDF";

export const BOOKINGS_LOADING = "BOOKINGS_LOADING";
export const SET_BOOKINGS = "SET_BOOKINGS";
export const SET_INITIAL_BOOKINGS_LOADING = "SET_INITIAL_BOOKINGS_LOADING";
export const CLEAR_BOOKINGS = "CLEAR_BOOKINGS";
export const SET_SINGLE_BOOKING = "SET_SINGLE_BOOKING";
export const SET_SELECTED_ROOMSTAY = "SET_SELECTED_ROOMSTAY";
export const TOGGLE_BOOKING_FAILED = "TOGGLE_BOOKING_FAILED";

export const ROUTE_CHANGED = "ROUTE_CHANGED";

export const GLOBAL_SET_TITLE = "GLOBAL_SET_TITLE";
export const GLOBAL_SET_LAST_BREADCRUMB = "GLOBAL_SET_LAST_BREADCRUMB";
export const GLOBAL_CLEAR_LAST_BREADCRUMB = "GLOBAL_CLEAR_LAST_BREADCRUMB";
export const GLOBAL_SET_UPGRADE_REQUIRED = "GLOBAL_SET_UPGRADE_REQUIRED";
export const GLOBAL_SET_CONTACTABLE_INFORMATION = "GLOBAL_SET_CONTACTABLE_INFORMATION";

export const GET_PORTAL_TYPE = "GET_PORTAL_TYPE";
export const SET_PORTAL_TYPE = "SET_PORTAL_TYPE";

export const UNIT_CONTRACTS_LOADING = "UNIT_CONTRACTS_LOADING";
export const SET_UNIT_CONTRACTS = "SET_UNIT_CONTRACTS";
export const CLEAR_UNIT_CONTRACTS = "CLEAR_UNIT_CONTRACTS";
export const SET_UNIT_CONTRACT = "SET_UNIT_CONTRACT";
export const TOGGLE_UNIT_CONTRACTS_FAILED = "TOGGLE_UNIT_CONTRACTS_FAILED";
export const SET_CREATE_BOOKING_LOADING = "SET_CREATE_BOOKING_LOADING";

export const SET_UNIT_CONTRACT_UNITS = "SET_UNIT_CONTRACT_UNITS";
export const SET_CREATE_BOOKING_SELECTED_UNIT = "SET_CREATE_BOOKING_SELECTED_UNIT";

export const SET_ROOM_STAYS = "SET_ROOM_STAYS";
export const SET_CURRENT_ROOM_STAYS = "SET_CURRENT_ROOM_STAYS";
export const SET_CALENDAR_DTOS = "SET_CALENDAR_DTOS";
export const SET_PAST_ROOM_STAYS = "SET_PAST_ROOM_STAYS";
export const SET_FUTURE_ROOM_STAYS = "SET_FUTURE_ROOM_STAYS";

export const CLEAR_ROOM_STAYS = "CLEAR_ROOM_STAYS";
export const ROOM_STAYS_LOADING = "ROOM_STAYS_LOADING";

export const SET_STATEMENTS = "SET_STATEMENTS";
export const CLEAR_STATEMENTS = "CLEAR_STATEMENTS";
export const STATEMENTS_LOADING = "STATEMENTS_LOADING";

export const GET_STATEMENT_ID = "GET_STATEMENT_ID";
export const SET_STATEMENT = "SET_STATEMENT";
export const CLEAR_STATEMENT = "CLEAR_STATEMENT";
export const STATEMENT_LOADING = "STATEMENT_LOADING";
export const STATEMENT_DOWNLOAD_TOGGLE = "STATEMENT_DOWNLOAD_TOGGLE";

export const REGISTER_GUEST = "REGISTER_GUEST";
export const REGISTER_GUEST_LOADING = "REGISTER_GUEST_LOADING";
export const REGISTER_GUEST_SUCCESS = "REGISTER_GUEST_SUCCESS";

export const SET_ROOM_STAY_UUID = "SET_ROOM_STAY_UUID";
export const SET_ROOM_STAY_INFO = "SET_ROOM_STAY_INFO";
export const SET_REGISTER_STATUS = "SET_REGISTER_STATUS";
export const SET_REGISTER_FAIL_MESSAGE = "SET_REGISTER_FAIL_MESSAGE";
export const RESET_REGISTRATION = "RESET_REGISTRATION";

export const SET_OWNER_CONFIG = "SET_OWNER_CONFIG";
export const SET_GUEST_CONFIG = "SET_GUEST_CONFIG";
export const SET_STRIPE_ENABLED = "SET_STRIPE_ENABLED";

export const EXTRAS_LOADING = "EXTRAS_LOADING";
export const EXTRAS_FAILED = "EXTRAS_FAILED";
export const SET_CURRENT_EXTRAS = "SET_CURRENT_EXTRAS";
export const SET_TARGET_EXTRAS = "SET_TARGET_EXTRAS";
export const SET_SELECTED_EXTRA_TARGET_ITEM = "SET_SELECTED_EXTRA_TARGET_ITEM";
export const EXTRA_TARGET_ITEM_LOADING = "EXTRA_TARGET_ITEM_LOADING";
export const EXTRA_TARGET_ITEM_FAIL = "EXTRA_TARGET_ITEM_FAIL";
export const SET_ADDING_EXTRAS_DETAILS = "SET_ADDING_EXTRAS_DETAILS";

export const SET_MARKETING_LISTS = "SET_MARKETING_LISTS";

export const SET_EXTRAS_CONFIG = "SET_EXTRAS_CONFIG";

export const SET_OWNER_SALES_INVOICES = "SET_OWNER_SALES_INVOICES";
export const SET_OWNER_LOADING_INVOICES = "SET_OWNER_LOADING_INVOICES";
export const SET_OWNER_FAILED_INVOICES = "SET_OWNER_FAILED_INVOICES";
export const SET_OWNER_INVOICES_TOTAL = "SET_OWNER_INVOICES_TOTAL";
export const SET_OWNER_INVOICE_DOWNLOADING_TOGGLE = "SET_OWNER_INVOICE_DOWNLOADING_TOGGLE";