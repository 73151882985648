import initialState from "../initialState";
import {
    CLEAR_UNIT_CONTRACTS,
    SET_UNIT_CONTRACT,
    SET_UNIT_CONTRACTS,
    TOGGLE_UNIT_CONTRACTS_FAILED,
    UNIT_CONTRACTS_LOADING,
    SET_UNIT_CONTRACT_UNITS,
    SET_CREATE_BOOKING_SELECTED_UNIT,
    SET_CREATE_BOOKING_LOADING,
} from "../actionTypes";

export function unitcontracts(state = initialState.unitContracts, action) {
    switch (action.type) {
        case SET_UNIT_CONTRACTS:
            console.log('SET_UNIT_CONTRACTS');
            return {...state, availableUnitContracts: action.data};

        case CLEAR_UNIT_CONTRACTS:
            console.log('CLEAR_UNIT_CONTRACTS');
            return {...state, availableUnitContracts: []};
        case UNIT_CONTRACTS_LOADING:
            console.log('UNIT_CONTRACTS_LOADING');
            return {...state, loading: action.toggle};
        case SET_UNIT_CONTRACT:
            console.log('SET_UNIT_CONTRACT');
            if(action.history && action.route) {
                //we need to reload;
                action.history.replace(`/reload`);
            }
            return {...state, selectedUnitContractId: action.unitContractId};
        case TOGGLE_UNIT_CONTRACTS_FAILED:
            console.log('TOGGLE_UNIT_CONTRACTS_FAILED');
            return {...state, failed: action.failed};
        case SET_UNIT_CONTRACT_UNITS:
            console.log('SET_UNIT_CONTRACT_UNITS');
            return {...state, units: action.data};
        case SET_CREATE_BOOKING_LOADING:
            console.log('SET_CREATE_BOOKING_LOADING');
            return {...state, createBookingloading: action.toggle};
        case SET_CREATE_BOOKING_SELECTED_UNIT:
            return {...state, createBookingSelectedUnit: action.selectedUnitId}
        default:
            return state;
    }
}
