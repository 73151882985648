export const initialState = {
	global: {
		title: "",
		lastBreadcrumb: "",
		upgradeRequired: false,
		portalType: false,
		ownerConfig: {
			showAccommodationTotals: true,
			showExtrasTotals: true,
			showGuestNames: true,
			showExtras: true,
			showChannel: true,
		},
		guestConfig: {
			stripeEnabled: false
		},
		contactableInformation: false
	},
	bookings: {
		loading: false,
		availableBookings: [],
		selectedBookingId: false,
		selectedRoomStayId: false,
		failed: false,
		finishedInitialBookingLoading: false
	},
	topnav: {
		sideMenuCollapsed: false,
		sideMenuBreakpoint: false,
	},
	dashboard: {
		loading: false,
		dashboard: [],
		failed: false,
		dashboardArrivals: [],
		selectedArrival: false,
	},
	bills: {
		loading: false,
		items: [],
		failed: false,
	},
	bill: {
		item: false,
		loading: false,
		failed: false,
		downloadLoading: false,
	},
	maintenances: {
		loading: false,
		targetItems: [],
		items: {},
		failed: false,
		filter: false,
		filterLoading: false,
	},
	maintenance: {
		item: false,
		loading: false,
		failed: false,
		notes: [],
		notesLoading: false,
		addMessageLoading: false,
		types: [],
		typesLoading: false,
		createLoading: false,
	},
	messages: {
		loading: false,
		targetItems: [],
		items: {},
		failed: false,
	},
	message: {
		loading: false,
		item: {},
		failed: false,
	},
	inventories: {
		loading: false,
		targetItems: [],
		items: {},
		failed: false,
	},
	inventory: {
		loading: false,
		failed: false,
		item: {},
		pdf: false,
		pdfLoading: false,
		pdfFailed: false,
	},
	unitContracts: {
		loading: false,
		failed: false,
		availableUnitContracts: [],
		selectedUnitContractId: false,
		units: [],
		createBookingSelectedUnit: null,
		createBookingloading: false,
	},
	roomstays: {
		loading: false,
		failed: false,
		currentRoomStays: [],
		pastRoomStays: [],
		futureRoomStays: [],
		calendarDtos: [],
	},
	statements: {
		loading: false,
		failed: false,
		items: [],
	},
	statement: {
		loading: false,
		failed: false,
		statementId: false,
		item: {},
		downloadLoading: false,
	},
	register: {
		roomstayInfo: false,
		roomstayUUID: false,
		successfullyGotRoomStayInfo: false,
		registerLoading: false,
		registrationState: "FORM",
		warningMessage: "",
		guestPortalEmailVerification: false
	},
	extras: {
		loading: false,
		targetItems: undefined,
		items: undefined,
		failed: false,
		selectedExtraTargetItem: undefined,
		extraTargetItemLoading: undefined,
		extraTargetItemFail: undefined,
		addingExtrasDetails: undefined,
		config: {}
	},
	marketing: {
		marketingLists: [],
	},
	ownerSalesInvoices: {
		loading: false,
		invoices: [],
		failed: false,
		totals: {},
		downloadingUUID: false,
	},
};

export default initialState;
