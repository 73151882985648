import initialState from "../initialState";
import {
    REGISTER_GUEST_LOADING,
    RESET_REGISTRATION,
    SET_REGISTER_FAIL_MESSAGE,
    SET_REGISTER_STATUS,
    SET_ROOM_STAY_INFO,
    SET_ROOM_STAY_UUID,
} from '../actionTypes';

export function registration(state = initialState.register, action) {
    switch(action.type) {
        case SET_ROOM_STAY_UUID:
            return {
                ...state,
                roomstayUUID: action.payload
            };
        case SET_ROOM_STAY_INFO:
            const { registerGuestPortalConfig } = action.payload;
            const { guestVerification } = registerGuestPortalConfig;
            return {
                ...state,
                roomstayInfo: action.payload,
                successfullyGotRoomStayInfo: action.payload.success,
                guestPortalEmailVerification: guestVerification
            };
        case REGISTER_GUEST_LOADING:
            return {
                ...state,
                registerLoading: action.flag
            };
        case SET_REGISTER_STATUS:
            return {
                ...state,
                registrationState: action.flag
            }
        case SET_REGISTER_FAIL_MESSAGE:
            return {
                ...state,
                warningMessage: action.message
            }
        case RESET_REGISTRATION: {
            return initialState.register;
        }
        default:
            return {
                ...state
            };
    }
}
