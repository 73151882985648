// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants

const configs = {
	"development": {
		"aws_user_pools": "enable",
		"aws_user_pools_id": "eu-west-1_tnPj8JD4P",
		"aws_user_pools_web_client_id": "6n1j87qdmonovovskhg2stn8s3"
	},
	"staging": {
		"aws_user_pools": "enable",
		"aws_user_pools_id": "eu-west-1_852HA2j9Z",
		"aws_user_pools_web_client_id": "7k1s4jjtah85m759oujtcimaar",
	},
	"demo": {
		"aws_user_pools": "enable",
		"aws_user_pools_id": "eu-west-1_gZmy0meth",
		"aws_user_pools_web_client_id": "5p9v6fl7pp4pfum3gcaad87vkr",
	},
	"stresidences": {
		"aws_user_pools": "enable",
		"aws_user_pools_id": "eu-west-1_rP650z5rG",
		"aws_user_pools_web_client_id": "7r37r3hbp751tq7gr3mrbs1ba2",
	},
	"escape2thesands": {
		"aws_user_pools": "enable",
		"aws_user_pools_id": "eu-west-1_9Q7kVTltv",
		"aws_user_pools_web_client_id": "4f0bqn94b22ru6jj7ehbsdasoq",
	},
    "thecollectivelive": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_2r2iE3HQT',
        'aws_user_pools_web_client_id': '5eb9vrnrdnhfgqi5vg0vboojb0'
    },
	"youarehome": {
		"aws_user_pools": "enable",
		"aws_user_pools_id": "eu-west-1_vhXAMcYKI",
		"aws_user_pools_web_client_id": "7u6q3hdhjv7pi6i1t952qga9j6",
	},
	"ldg": {
		"aws_user_pools": "enable",
		"aws_user_pools_id": "eu-west-1_Kvq6tvygp",
		"aws_user_pools_web_client_id": "5g8jalk4ahdvq8tv7fp3fm8adb",
	},
	"clarendon": {
		"aws_user_pools": "enable",
		"aws_user_pools_id": "eu-west-1_GkMhWf4qE",
		"aws_user_pools_web_client_id": "4o9qdctdlbsfe216lsqiopc3ku",
	},
    "signetapartments": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_TP7ZU04an',
        'aws_user_pools_web_client_id': '2lqi8uesp2or2fqim27unr9b50'
    },
    "charmstay": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_a6tFWPHxS',
        'aws_user_pools_web_client_id': 'fko8ud3fenr7iqlaed9m50kae'
    },
    "cosmopolitanapartments": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_W7LScftGR',
        'aws_user_pools_web_client_id': '2kjofsm86dsbm5lmvq50u40v8b'
    },
    "citystayapartment": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_NmWNG7tCT',
        'aws_user_pools_web_client_id': '31u3gv37elstqs09pm5v4mqo5j'
    },
	"roomspaceuat": {
		'aws_user_pools': 'enable',
		'aws_user_pools_id': 'eu-west-1_0CP2hntyn',
		'aws_user_pools_web_client_id': '5hsmvu3tdfmj2nk5h84uaqn76k'
	},
    "roomspace": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_rrsmMIRK1',
        'aws_user_pools_web_client_id': '6a949th8pa8aqqiuofqmgrmg00'
    },
    "harrogateapartments": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_1Zl5tMpAB',
        'aws_user_pools_web_client_id': '7t2i8frdp6gpoun5onqgfjd72a'
    },
    "tempstay": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_vVn8b1wMH',
        'aws_user_pools_web_client_id': '2kll5vmciajsgm1a50395ae6ph'
    },
    "kepplestonemanor": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_ymp6tE6Gb',
        'aws_user_pools_web_client_id': '4mpibii1rbg5r645ob7djrbnha'
    },
    "bnbmeindia": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_h9oeHVyRR',
        'aws_user_pools_web_client_id': '4plugl64bk36s9r09tmuflqf21'
    },
    "scandik": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_ms8yhoKbc',
        'aws_user_pools_web_client_id': '3tgg3826nmvjffl59n7u0aqqce'
    },
    "bnbmesaudi": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_f1KROuoj8',
        'aws_user_pools_web_client_id': '3p607g27qfppemg3g71g7nruqd'
    },
    "bnbme": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_yhVNaxu0s',
        'aws_user_pools_web_client_id': '3ms6jomuo884pbva4ji7f5mkc8'
    },
    "multicurrencytest": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_9C7SqAKtU',
        'aws_user_pools_web_client_id': '7elfo3t9b8l9th3am8kjcobekk'
    },
    "tjpad": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_JS7vrbgSp',
        'aws_user_pools_web_client_id': 'dgotfr0vhu3m7butctbv8au56'
    },
    "flyingbutler": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_T7AnndF45',
        'aws_user_pools_web_client_id': '3cbbk70d5irs70cqp5dclseq40'
    },
    "sixstays": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_HTH5ENSKO',
        'aws_user_pools_web_client_id': '1dc211tnv7865f07fv56kcd89v'
    },
    "zomelle": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_Bd91LpO3I',
        'aws_user_pools_web_client_id': '58uhere4o7rv2eq9lajtn46152'
    },
    "buckinghamandlloyds": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_IA2gcvXmj',
        'aws_user_pools_web_client_id': '4io4mvhiucj1tqopko83tk9bnl'
    },
    "domusstay": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_RCuM5989d',
        'aws_user_pools_web_client_id': '1o8vo5edgnbsg76jmhk0el53qu'
    },
    "qapartments": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_klKLSc2mq',
        'aws_user_pools_web_client_id': 'ht4chcdjo19hvj0m5h4cff9fb'
    },
    "markeygroup": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_3JODPTZtA',
        'aws_user_pools_web_client_id': '59tdvgbfi0vqjrgbit3tgvjn8q'
    },
    "multicurrencytest2": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_2TjW7Sf5t',
        'aws_user_pools_web_client_id': 'ku6cuf69p0j70g27k76gq5sfb'
    },
	"multicurrencytest3": {
		'aws_user_pools': 'enable',
		'aws_user_pools_id': 'eu-west-1_tnPj8JD4P',
		'aws_user_pools_web_client_id': '6n1j87qdmonovovskhg2stn8s3'
	},
    "mabaat": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_pb84qpUwu',
        'aws_user_pools_web_client_id': '2ildud1karohoh6o7uveuss24a'
    },
    "week2week": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_SGTpmjufM',
        'aws_user_pools_web_client_id': '455ncfdfld2cr1ek1ejlgovsqg'
    },
    "homenhancement": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_xdss8u3j1',
        'aws_user_pools_web_client_id': '92iosg6gurmteuvf1b559i36c'
    },
    "thecollective": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_znlklPBUg',
        'aws_user_pools_web_client_id': '6tau37o8v79bt7bulck3i22h47'
    },
    "citywestaparthotel": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_1HG1Qg6H2',
        'aws_user_pools_web_client_id': 'p3f5tdnu8m27fgne5huksn62b'
    },
    "khhomes": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_6f9Umew0c',
        'aws_user_pools_web_client_id': '47hrbfbkc076ueqn8ug0dm7ajp'
    },
    "basilstreetapartments": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_Tda7xNEly',
        'aws_user_pools_web_client_id': '95eam5p4384qfin6m615rq05s'
    },
    "homa": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_7o2TQ1VXV',
        'aws_user_pools_web_client_id': '1bcdh1tl3o66o8iilqbdbdt471'
    },
    "stayo": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_DHIxm8uJu',
        'aws_user_pools_web_client_id': '13r1bfs4oug8g784ug0bsg0rcb'
    },
    "swisslodginggmbh": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_EYGErSL9P',
        'aws_user_pools_web_client_id': '40e36esmgm5jnsv8t56ljbciv7'
    },
    "tailoredstays": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_6jJaSo2to',
        'aws_user_pools_web_client_id': '5gppati63mhino8p5rokk53a3g'
    },
	"aaronwise": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_4yJQcBa8Z',
        'aws_user_pools_web_client_id': '47sqvd7let02pphur30n68pkh5'
	},
	"aqora": {
		'aws_user_pools': 'enable',
		'aws_user_pools_id': 'eu-west-1_8hRDdmNP2',
		'aws_user_pools_web_client_id': '7i8lir1klf8u003o7b78evq3pe'
    },
	"helmswood": {
		'aws_user_pools': 'enable',
		'aws_user_pools_id': 'eu-west-1_ElFdgGQau',
		'aws_user_pools_web_client_id': '5nm7moq62la1l3lkjj2o3t30d4'
    },
	"livingtown": {
		'aws_user_pools': 'enable',
		'aws_user_pools_id': 'eu-west-1_9JSLKncxX',
		'aws_user_pools_web_client_id': '21gv11tm14pjjb2ojm1icolit1'
    },
	"beyondapartments": {
		'aws_user_pools': 'enable',
		'aws_user_pools_id': 'eu-west-1_aamRpunEV',
		'aws_user_pools_web_client_id': '7sictril6rcld6nuorur8sc971'
    },
    "briscoeandshaw": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_7T27Hn6AO',
        'aws_user_pools_web_client_id': '5gigjdip6qvj0fholi0fvugdar'
    },
    "ariv": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_d9Q3lu1RO',
        'aws_user_pools_web_client_id': '40mrir983kmf5c22lk9age2lvm'
    },
    "shortstaycrawley": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_FySDXBe4P',
        'aws_user_pools_web_client_id': '1b1sgfq9i5da8get9r2c5cq4l7'
    },
    "gravitycoliving": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_Ls2j2Q2O7',
        'aws_user_pools_web_client_id': '4bs48eqjuok6bkfcdr6ssneptj'
    },
    "cotels": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_xOhyWf7EW',
        'aws_user_pools_web_client_id': '3ir22uccvd0upveap8a8djlaq0'
    },
    "heliodoorapartments": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_fBLWwYeTj',
        'aws_user_pools_web_client_id': '4r4vhshsalqjkmoqqfnq6q7t76'
    },
    "bbf": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_wooX7TvmM',
        'aws_user_pools_web_client_id': '65oaervh863qpcrknopphdddv4'
    },
    "360apartments": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_ZME2esFpe',
        'aws_user_pools_web_client_id': '73kqnd50vjehj2vct0gfplkuh9'
    },
    "mgroupservicedaccommodation": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_rSpyjQpNY',
        'aws_user_pools_web_client_id': '1re6nbqbp5k1h0v8e0b4lq6c3v'
    },
	"mcdemo": {
		'aws_user_pools': 'enable',
		'aws_user_pools_id': 'eu-west-1_iALWq0ort',
		'aws_user_pools_web_client_id': '5pfhe64vi6a536fd4tn73sghfn'
	},
    "propertybycapital": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_AVn0xDDT5',
        'aws_user_pools_web_client_id': '4915vhabblc8spe0hfvl7ndvl5'
    },
    "swedenlongstay": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_5KvC3jUZ7',
        'aws_user_pools_web_client_id': '2bjiqgmi1ua7nhmtdsqn722qm'
    },
    "training": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_dv1XLwCwC',
        'aws_user_pools_web_client_id': '5f6cio6m82maevd6mrlao10jud'
    },
    "mialiving": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_imsFKHFyl',
        'aws_user_pools_web_client_id': '3pvk72dtnnd499djqtdbr5vbbn'
    },
    "homex": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_UVwgKBZ0V',
        'aws_user_pools_web_client_id': '19kjpduqh7g2hog6ml001jru9k'
    },
    "alohause": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_z33D9wNGj',
        'aws_user_pools_web_client_id': '4vlr8s7tmnb9pbj1kmetdnra17'
    },
    "smarterrent": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_dJqSaQG6G',
        'aws_user_pools_web_client_id': '1atao4kjm0mfbc2uivgqo4j76f'
    },
    "alohausees": {
            'aws_user_pools': 'enable',
            'aws_user_pools_id': 'eu-west-1_kJFvlgom7',
            'aws_user_pools_web_client_id': '617ao2k1nf2kcmfc07fesuhdeh'
    },
    "diffrent": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_ECmQ1pxst',
        'aws_user_pools_web_client_id': '7a91eltc24efcdlaa5isv5qgmq'
    },
    "canarywharf": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_TVVkDwqDr',
        'aws_user_pools_web_client_id': '3pqb7soktuf93hrbuldcdjtf3v'
    },
    "vitanovae": {
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'eu-west-1_rQeWdbSOh',
        'aws_user_pools_web_client_id': '7v4ckmbjjm5oll51e1kvbkovi5'
    },
		"skyside": {
				'aws_user_pools': 'enable',
				'aws_user_pools_id': 'eu-west-1_lr8Ksn68E',
				'aws_user_pools_web_client_id': '7re0943t86tc1kahql2itks2m6'
		},
		"southallandsoul": {
				'aws_user_pools': 'enable',
				'aws_user_pools_id': 'eu-west-1_cTMElIIdx',
				'aws_user_pools_web_client_id': '2o7qslok99729mihqhqulhhse'
		},
		"shoreditchandsoul": {
				'aws_user_pools': 'enable',
				'aws_user_pools_id': 'eu-west-1_t8cFI3IwM',
				'aws_user_pools_web_client_id': '6tc4a5onj22167472nqgan3hnt'
		},
};

export default function(env) {
	if (configs[env] !== undefined) {
		return configs[env];
	}
	return configs.development;
}
