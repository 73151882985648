import initialState from "../initialState";
import {
    GET_STATEMENT_ID,
    SET_STATEMENT,
    CLEAR_STATEMENT,
    STATEMENT_LOADING,
    STATEMENT_DOWNLOAD_TOGGLE
} from "../actionTypes";

export function statement(state = initialState.statement, action) {
    switch (action.type) {
        case GET_STATEMENT_ID:
            console.log('GET_STATEMENT_ID');
            return {...state, item: action.data};
        case SET_STATEMENT:
            console.log('SET_STATEMENT');
            return {...state, item: action.data};
        case CLEAR_STATEMENT:
            console.log('CLEAR_STATEMENT');
            return {...state, item: {}};
        case STATEMENT_LOADING:
            console.log('STATEMENT_LOADING');
            return {...state, loading: action.toggle};
        case STATEMENT_DOWNLOAD_TOGGLE:
            return {...state, downloadLoading: action.toggle};
        default:
            return state;
    }
}
