import initialState from "../initialState";
import {
	BOOKINGS_LOADING,
	CLEAR_BOOKINGS,
	SET_BOOKINGS, SET_INITIAL_BOOKINGS_LOADING,
	SET_SELECTED_ROOMSTAY,
	SET_SINGLE_BOOKING,
	TOGGLE_BOOKING_FAILED,
} from "../actionTypes";
import api from '../../api/api';

let interceptor = false;
let roomstayInterceptor = false;

export function bookings(state = initialState.bookings, action) {
	switch (action.type) {
		case SET_BOOKINGS:
			console.log('SET_BOOKINGS');
			return {...state, availableBookings: action.data};
		case SET_INITIAL_BOOKINGS_LOADING:
			return {
				...state,
				finishedInitialBookingLoading: action.flag
			}
		case CLEAR_BOOKINGS:
			console.log('CLEAR_BOOKINGS');
			return {...state, availableBookings: []};
		case BOOKINGS_LOADING:
			console.log('BOOKINGS_LOADING');
			return {...state, loading: action.toggle};
		case SET_SINGLE_BOOKING:
			console.log('SET_SINGLE_BOOKING');
			if(interceptor) {
				api.interceptors.request.eject(interceptor);
			}
			interceptor = api.interceptors.request.use(config => {
				config.headers['X-Booking-Id'] = action.bookingId;
				return Promise.resolve(config);
			});

			if(action.history && action.route) {
				//we need to reload;
				action.history.replace(`/reload`);
				/*setTimeout(() => {
					action.history.replace(action.route);
				});*/
			}
			return {...state, selectedBookingId: action.bookingId};
		case SET_SELECTED_ROOMSTAY:
			console.log('SET_SELECTED_ROOMSTAY');
			if (roomstayInterceptor) {
				api.interceptors.request.eject(roomstayInterceptor);
			}
			roomstayInterceptor = api.interceptors.request.use(config => {
				config.headers['X-Roomstay-Id'] = action.roomstayUUID;
				return Promise.resolve(config);
			});
			return {...state, selectedRoomStayId: action.roomstayUUID};
		case TOGGLE_BOOKING_FAILED:
			console.log('TOGGLE_BOOKING_FAILED');
			return {...state, failed: action.failed};
		default:
			return state;
	}
}
