import initialState from "../initialState";
import {CLEAR_MESSAGES, MESSAGES_LOADING, SET_MESSAGES, SET_MESSAGES_TARGET_ITEMS} from "../actionTypes";

export function messages(state = initialState.messages, action) {
	switch(action.type) {
		case SET_MESSAGES:
			console.log('SET_MESSAGES');
			return {...state, items: action.data};
		case SET_MESSAGES_TARGET_ITEMS:
			console.log('SET_MESSAGES_TARGET_ITEMS');
			return {...state, targetItems: action.items};
		case MESSAGES_LOADING:
			console.log('MESSAGES_LOADING');
			return {...state, loading: action.toggle};
		case CLEAR_MESSAGES:
			console.log('CLEAR_MESSAGES');
			return {...state, items: {}, targetItems: []};
		default:
			return state;
	}
}
